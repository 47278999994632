
import Vue from 'vue'
import { Location } from 'vue-router'
import {
  AtriumLayout,
  HeroCard,
  BoxContent,
  ColorWidget,
  SponsorGrid,
  FeaturedSessions,
  SponsorGroup,
  deepSeal,
  Routes,
  SessionAndSlot,
  mapMetricsState,
  ApiContent,
  PrimaryEmbed,
  getFeaturedSessions,
  ScheduleConfig,
  SessionTile,
} from '@openlab/deconf-ui-toolkit'
import AppLayout from '@/components/MozAppLayout.vue'
import TitoWidget from '@/components/TitoWidget.vue'
import { mapApiState, MozConferenceConfig } from '@/lib/module'
import { Session } from '@openlab/deconf-shared'

import sponsorData from '@/data/sponsors.json'

interface StaticWidget {
  public: boolean
  key: string
  title: string
  subtitle?: string
  href?: string
  kind: string
  icon: [string, string]
  classes?: string
}

const staticWidgets: StaticWidget[] = deepSeal([
  {
    key: 'calendarHelp',
    public: false,
    kind: 'secondary',
    title: 'mozfest.atrium.calendarHelpTitle',
    subtitle: 'mozfest.atrium.calendarHelpSubtitle',
    href: 'mozfest.atrium.calendarHelpUrl',
    icon: ['fas', 'calendar-plus'],
  },
  {
    key: 'twitter',
    public: true,
    kind: 'twitter',
    title: 'mozfest.atrium.twitterTitle',
    subtitle: 'mozfest.atrium.twitterSubtitle',
    icon: ['fab', 'twitter'],
    href: 'mozfest.atrium.twitterUrl',
  },
  {
    key: 'spatialChat',
    public: false,
    kind: 'custom',
    classes: 'is-spatialChat',
    title: 'mozfest.atrium.spacialChatTitle',
    subtitle: 'mozfest.atrium.spacialChatSubtitle',
    icon: ['fas', 'headset'],
    href: 'mozfest.atrium.spacialChatUrl',
  },
  {
    key: 'hubs',
    public: false,
    kind: 'custom',
    classes: 'is-spatialChat',
    title: 'mozfest.atrium.hubsTitle',
    subtitle: 'mozfest.atrium.hubsSubtitle',
    icon: ['fas', 'cubes'],
    href: 'mozfest.atrium.hubsUrl',
  },
  {
    key: 'slack',
    public: true,
    kind: 'custom',
    classes: 'is-slack',
    title: 'mozfest.atrium.slackTitle',
    subtitle: 'mozfest.atrium.slackSubtitle',
    icon: ['fab', 'slack'],
    href: 'mozfest.atrium.slackUrl',
  },
  {
    key: 'discord',
    public: true,
    kind: 'custom',
    classes: 'is-discord',
    title: 'mozfest.atrium.discordTitle',
    subtitle: 'mozfest.atrium.discordSubtitle',
    icon: ['fab', 'discord'],
    href: 'mozfest.atrium.discordUrl',
  },
  {
    key: 'linkedin',
    public: true,
    kind: 'custom',
    classes: 'is-linkedin',
    title: 'mozfest.atrium.linkedinTitle',
    subtitle: 'mozfest.atrium.linkedinSubtitle',
    icon: ['fab', 'linkedin'],
    href: 'mozfest.atrium.linkedinUrl',
  },
  {
    key: 'submissions',
    public: true,
    kind: 'secondary',
    title: 'mozfest.atrium.submissionsTitle',
    subtitle: 'mozfest.atrium.submissionsSubtitle',
    icon: ['fas', 'lightbulb'],
    href: 'mozfest.atrium.submissionsUrl',
  },
  {
    key: 'familyResources',
    public: false,
    kind: 'custom',
    classes: 'is-family',
    title: 'mozfest.atrium.familyTitle',
    subtitle: 'mozfest.atrium.familySubtitle',
    icon: ['fas', 'puzzle-piece'],
    href: 'mozfest.atrium.familyUrl',
  },
  {
    key: 'mastodon',
    public: true,
    kind: 'custom',
    classes: 'is-mastodon',
    title: 'mozfest.atrium.mastodonTitle',
    subtitle: 'mozfest.atrium.mastodonSubtitle',
    icon: ['fab', 'mastodon'],
    href: 'mozfest.atrium.mastodonUrl',
  },
  {
    key: 'mozfestBook',
    public: true,
    kind: 'custom',
    classes: 'is-book',
    title: 'mozfest.atrium.bookTitle',
    subtitle: 'mozfest.atrium.bookSubtitle',
    icon: ['fas', 'book'],
    href: 'mozfest.atrium.bookUrl',
  },
])

const pinnedConfig: ScheduleConfig = {
  tileHeader: ['type'],
  tileAttributes: ['track', 'themes'],
  tileActions: ['join'],
}

interface Data {
  sponsors: SponsorGroup[]
  staticWidgets: StaticWidget[]
  pinnedConfig: ScheduleConfig
}

export default Vue.extend({
  components: {
    ApiContent,
    PrimaryEmbed,
    AppLayout,
    TitoWidget,
    AtriumLayout,
    HeroCard,
    BoxContent,
    ColorWidget,
    SponsorGrid,
    FeaturedSessions,
    SessionTile,
  },
  data(): Data {
    return {
      sponsors: deepSeal(sponsorData),
      staticWidgets,
      pinnedConfig,
    }
  },
  computed: {
    ...mapApiState('api', ['schedule', 'user']),
    ...mapMetricsState('metrics', ['siteVisitors']),
    settings(): MozConferenceConfig | null {
      return this.schedule?.settings ?? null
    },
    siteVisitorsTitle(): string {
      return this.siteVisitors?.toString() ?? '~'
    },
    contentSlug(): string {
      return this.user ? 'atrium-active' : 'atrium-public'
    },
    loginRoute(): Location {
      return { name: Routes.Login }
    },
    registerRoute(): Location {
      return { name: Routes.Register }
    },
    scheduleDate(): Date {
      return this.$dev.scheduleDate ?? this.$temporal.date
    },

    featuredSessions(): SessionAndSlot[] {
      if (!this.schedule) return []
      if (!this.schedule.settings.schedule.enabled) return []

      return (
        getFeaturedSessions(
          this.schedule,
          7,
          this.scheduleDate,
          (s) => Boolean(s.slot) && s.isFeatured
        )?.slice(0, 3) ?? []
      )
    },
    pinnedSessions(): Session[] {
      if (!this.schedule) return []
      if (!this.schedule.settings.schedule.enabled) return []
      const sessions = new Map(this.schedule.sessions.map((s) => [s.id, s]))
      return this.schedule.settings.content.featuredSessions
        .map((id) => sessions.get(id) as Session)
        .filter((s) => Boolean(s))
    },
    widgets(): Set<string> {
      return new Set(
        Object.entries(this.settings?.atriumWidgets ?? {})
          .filter((entry) => entry[1] === true)
          .map((entry) => entry[0])
      )
    },
    featuredVideoLink(): string | undefined {
      try {
        const raw = this.settings?.content.atriumVideo
        if (!raw) return undefined
        return new URL(raw).toString()
      } catch {
        return undefined
      }
    },
    activeStaticWidgets(): StaticWidget[] {
      return this.staticWidgets.filter((w) => this.showStaticWidget(w))
    },
  },
  methods: {
    showStaticWidget(widget: StaticWidget): boolean {
      if (!this.widgets.has(widget.key)) return false
      return widget.public ? true : Boolean(this.user)
    },
  },
})
