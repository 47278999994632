
import Vue from 'vue'
import { loadScript } from '@/lib/module'

interface Data {
  scriptLoaded: boolean
}

export default Vue.extend({
  data(): Data {
    return { scriptLoaded: false }
  },
  async mounted() {
    await loadScript('tito-widget', 'https://js.tito.io/v2')
    this.scriptLoaded = true
  },
})
