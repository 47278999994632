
import Vue from 'vue'
import { Location } from 'vue-router'
import { BackButton, Routes, UtilLayout } from '@openlab/deconf-ui-toolkit'

import MozfestLogo from './MozfestLogo.vue'
import MozPageFooter from './MozPageFooter.vue'
import LanguageControl from '@/components/LanguageControl.vue'

export default Vue.extend({
  components: {
    UtilLayout,
    MozfestLogo,
    MozPageFooter,
    BackButton,
    LanguageControl,
  },
  props: {
    width: { type: String, default: 'regular' },
  },
  computed: {
    atriumRoute(): Location {
      return { name: Routes.Atrium }
    },
  },
})
